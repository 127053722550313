<template>
  <div>
    <CModal :show.sync="modalEmail.show" :centered="true" :title="$t('Email control')" size="lg">
      <template v-if="modalEmail.show">
        <ModalEmail :entity="modalEmail.receipt" :recipients="modalEmail.recipients" :contact_types="modalEmail.contact_types" subject_prefix="Recibo de Caja de Administración - " @modalUpdated="onModalEmailUpdated" @parentHide="modalEmail.show = false" />
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>

    <ACRUDTable
      ref="crud_table"
      :fields="
        fields || [
          //'id',
          { key: 'consecutive', label: 'Receipt' },
          helpers.monthName,
          { key: 'month_id', _classes: 'hide' },
          helpers.identifierText,
          helpers.ownerNameText,
          helpers.currencyValue,
          //{ ...helpers.currencyValue, key: 'discount_value', label: 'with Discount', _hide: !discount }, // TODO: Show only on month with discounts
          { key: 'options', filter: false, _classes: 'text-center', _style: 'width: 180px;' }
        ]
      "
      :config="{
        name: 'receipt',
        url: 'receipts', // + (this.building_id ? `?conditions=building_id=${this.building_id}` : ''),
        //route: '/no-users/receipts',
        params: { _whits: 'early_payment_discount', _lists: 'months,contact_types,building_types,property_units', building_id: this.building_id || 0, ...(params || {}) },
        //filters: filters ? [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'building_code', use: 'building.identifier' }, { key: 'building_type', config: { select_all: true } }, { key: 'property_unit', config: { select_all: true } }, 'owner_name'] : [],
        noCreate: true,
        noOptions: true,
        ...(config || {})
      }"
      @dataChanged="onDataChanged"
      @preparedHelpers="onPreparedHelpers"
    >
      <template #options="{item}">
        <td style="text-align: center; white-space: nowrap;">
          <CButton type="button" class="mr-2" color="info" @click="getReceipt('download', item)"><CIcon name="cil-cloud-download"/></CButton>
          <CButton type="button" class="mr-2" color="light" @click="getReceipt('preview', item)"><CIcon name="cil-low-vision"/></CButton>
          <CButton type="button" class="mr-2" color="danger" @click="onEmailReceipt(item)"><CIcon name="cil-envelope-closed"/></CButton>
        </td>
      </template>
    </ACRUDTable>
  </div>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'receiptsTable',
  mixins: [crudMixin],
  props: ['building_id', 'fields', 'filters', 'config', 'params'],
  watch: {
    building_id(new_value, old_value) {
      if (new_value && new_value !== old_value) this.onGetData(new_value)
    }
  },
  data() {
    return {
      month: {},
      checked: [],
      discount: false,
      modalEmail: {
        show: false,
        receipt: false,
        recipients: []
      }
    }
  },
  created() {
    if (this.property.id) {
      this.month = this.periods.REC
      // TODO: Use a UUID for every request ?
      const channel = this.$pusher.subscribe(`channel-${this.property.id}`) // TODO: unbind beforeDestroy ???
      channel.bind(`event-${this.property.id}`, data => {
        if (data.message === 'GenerateReceipts') this.events = data
      })
    }
  },
  methods: {
    getReceipt(type, receipt) {
      const self = this
      if (type === 'preview') {
        // TODO: create a function
        self.$http
          .get(`admin/receipts/${receipt.id}/${type}`)
          .then(response => {
            window.open(response.data.signed_url, `receipt-${receipt.consecutive}.pdf`)
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            self.resetEventToaster()
          })
      } else {
        // TODO: create a function ???
        self.$http
          .getBlob(`admin/receipts/${receipt.id}/${type}` + (type === 'email' ? '?is_digital=true' : ''))
          .then(response => {
            if (type === 'download') {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
              const fileLink = document.createElement('a')

              fileLink.href = fileURL
              fileLink.setAttribute('download', `receipt-${receipt.consecutive}.pdf`)
              document.body.appendChild(fileLink)

              fileLink.click()
            }
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            self.resetEventToaster()
          })
      }
    },

    onEmailReceipt(receipt) {
      // TODO: create a common function to inject identifier
      /*if (!receipt.building_identifier) {
        receipt.building_identifier = `${this.view_helpers.building_types[receipt.building.building_type_id].name} ${this.view_helpers.property_units[receipt.building.property_unit_id].name}-${receipt.building.code}`
      }*/
      receipt.building_identifier = receipt.building.identifier

      this.modalEmail.recipients = [
        { name: 'Send copy to', emails: [{ email: '', checked: false }], type: 'others' },
        { name: 'Admon copy', emails: [{ email: this.property.email, checked: false }], readonly: true, type: 'others' },
        { name: 'Copy me', emails: [{ email: this.loggedUser.email, checked: false }], readonly: true, type: 'others' }
      ]

      // TODO: order by type_id ??
      for (let contact in receipt.building.contacts.filter(contact => contact.type_id && contact.type_id < 3)) {
        contact = receipt.building.contacts[contact]
        contact.contact.emails.forEach(email => {
          email.checked = false
        })
        this.modalEmail.recipients.push({ ...contact, readonly: true, type: contact.type_id === 1 ? 'to' : 'cc', checked: false, emails: contact.contact.emails })
      }

      this.modalEmail.show = true
      this.modalEmail.receipt = receipt
      this.modalEmail.contact_types = this.view_helpers.contact_types
    },

    onModalEmailUpdated(params) {
      const self = this
      if (params.checked.length) {
        const recipients = { to: [], cc: [], others: [] }
        for (const recipient of params.checked) {
          recipients[recipient.type].push(recipient.email)
        }
        self.$http
          //.get(`admin/receipts/${this.modalEmail.receipt.id}/email?subject=${this.modalEmail.receipt.building_identifier}&` + new URLSearchParams(recipients).toString())
          .get(`admin/receipts/${this.modalEmail.receipt.id}/email?prefix=${params.prefix}&` + new URLSearchParams(recipients).toString())
          .then(response => {
            console.log(response)
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            self.resetEventToaster()
          })
      }
      this.modalEmail.show = false
      this.modalEmail.receipt = false
    },

    onGetData(building_id) {
      // if (month_ids) {
      // this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { building_id }, true)
      // }
    },
    onDataChanged() {
      this.checked = this.$refs.crud_table.getChecked()
    }
  }
}
</script>
